html {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fb;
  line-height: 1.3rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: #662482;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
input,
select,
textarea,
.pure-g [class*='pure-u'] {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: #ffc900;
  color: #4e5974;
}
::-moz-selection {
  background-color: #ffc900;
  color: #4e5974;
}

.text-center {
  text-align: center;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
}

.content > * {
  padding-left: 15px;
  padding-right: 15px;
}

.img-responsive {
  max-width: 100%;
  display: block;
  height: auto;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .content {
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .content {
    max-width: 992px;
  }
}

@media screen and (min-width: 1200px) {
  .content {
    max-width: 1200px;
  }
}
