div,
input,
label,
button,
a {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

/* Padding */
.p0 {
  padding: 0px;
}

.p1 {
  padding: 15px;
}

.p2 {
  padding: 30px;
}

.px0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px1 {
  padding-left: 15px;
  padding-right: 15px;
}

.px2 {
  padding-left: 30px;
  padding-right: 30px;
}

.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py1 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py2 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Margin */
.m0 {
  margin: 0px;
}

.m1 {
  margin: 15px;
}

.m2 {
  margin: 30px;
}

.mx0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx1 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx2 {
  margin-left: 30px;
  margin-right: 30px;
}

.my0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my2 {
  margin-top: 30px;
  margin-bottom: 30px;
}
