.bg-container {
  width: 100%;
}

.bg-left,
.bg-right {
  position: absolute;
}

.bg-left {
  left: 0px;
  bottom: 5%;
}

.bg-right {
  right: 0px;
}

.fig1,
.fig2,
.fig3 {
  position: absolute;
}

.fig1 {
  top: 20%;
  left: 70%;
}

.fig2 {
  top: 50%;
  left: 28%;
}

.fig3 {
  top: 70%;
  left: 65%;
}
